import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Core/Global/Home.vue'),
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/admin/Dashboard.vue'),
    },
    {
      path: '/tester',
      name: 'tester',
      component: () => import('@/views/test.vue'),
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login.vue'),
    },
    {
      path: '/clip',
      name: 'clip',
      component: () => import('@/components/clipping/Clip.vue'),
    },
    {
      path: '/banned/:channel',
      name: 'banned',
      component: () => import('@/views/Core/Channels/Banned.vue'),
    },
    {
      path: '/squad/:channel1/:channel2',
      name: 'squad',
      component: () => import('@/views/Core/Squad/Squad.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/devdocs/callback',
      name: 'devdocs',
      component: () => import('@/views/Integrations/DevDocs/Callback.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('@/views/Core/Billing/Billing.vue'),
      meta: {
        pageTitle: 'Subscriptions',
        breadcrumb: [
          {
            text: 'Subscriptions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/:channel/chat',
      name: 'channel-chat',
      component: () => import('@/views/OBS/chat.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:channel/mobile-chat',
      name: 'channel-chat-mobile',
      component: () => import('@/views/OBS/mobile-chat.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:channel/chat-overlay',
      name: 'channel-chat-overlay',
      component: () => import('@/views/OBS/chat-overlay.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:channel/stats',
      name: 'channel-stats',
      component: () => import('@/views/OBS/stats.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:channel/stream',
      name: 'channel-stream',
      component: () => import('@/views/OBS/stream.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import('@/views/Core/Global/Stats.vue'),
      meta: {
        pageTitle: 'Platform Stats',
        breadcrumb: [
          {
            text: 'Platform Stats',
            active: true,
          },
        ],
      },
    },
    {
      path: '/integrations/hellonext',
      name: 'hellonext',
      component: () => import('@/views/Integrations/HelloNext/Login.vue'),
      meta: {
        pageTitle: 'Feedback Login',
        breadcrumb: [
          {
            text: 'Feedback Login',
            active: true,
          },
        ],
      },
    },
    {
      path: '/player',
      name: 'player',
      component: () => import('@/components/global/Player.vue'),
    },
    {
      path: '/account/settings',
      name: 'account-settings',
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],
      },
      component: () => import('@/views/User-Account/AccountSettings.vue'),
    },
    {
      path: '/studio',
      name: 'studio',
      meta: {
        pageTitle: 'Creator Studio',
        breadcrumb: [
          {
            text: 'Creator Studio',
            active: true,
          },
        ],
      },
      component: () => import('@/views/Core/CreatorStudio/Studio.vue'),
    },
    {
      path: '/studio/:section',
      name: 'creator-studio',
      meta: {
        pageTitle: 'Creator Studio',
        breadcrumb: [
          {
            text: 'Creator Studio',
            active: true,
          },
        ],
      },
      component: () => import('@/views/Core/CreatorStudio/Studio.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/User-Account/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/clip_player/:xid',
      name: 'clipPlayer',
      props: true,
      component: () => import('@/views/Core/Player/ClipPlayer.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/z_Error-Pages/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/test',
      name: 'test',
      props: true,
      component: () => import('../components/carousel.vue'),
    },
    {
      path: '/integrations/streamlabs',
      props: true,
      component: () => import('@/views/Integrations/Streamlabs/Callback.vue'),
    },
    {
      path: '/callback/streamlabs',
      props: true,
      component: () => import('@/views/callback/streamlabs.vue'),
    },
    {
      path: '/privacy',
      name: 'PrivacyPolicy',
      props: true,
      component: () => import('@/views/Core/Global/PrivacyPolicy.vue'),
    },
    {
      path: '/tos',
      name: 'ToS',
      props: true,
      component: () => import('@/views/Core/Global/ToS.vue'),
    },
    {
      path: '/callback/twitch',
      props: true,
      component: () => import('@/views/callback/twitch.vue'),
    },
    {
      path: '/callback/youtube',
      props: true,
      component: () => import('@/views/callback/youtube.vue'),
    },
    {
      path: '/callback/trovo',
      props: true,
      component: () => import('@/views/callback/trovo.vue'),
    },
    {
      path: '/category/:slug',
      name: 'category',
      props: true,
      component: () => import('@/views/Core/Categories/Category.vue'),
    },
    {
      path: '/categories',
      name: 'categories',
      props: true,
      meta: {
        pageTitle: 'Categories',
        breadcrumb: [
          {
            text: 'Categories',
            active: true,
          },
        ],
      },
      component: () => import('@/views/Core/Categories/LiveCategories.vue'),
    },
    {
      path: '/:id/clips',
      name: 'Clips',
      component: () => import('@/views/Core/Global/MyClips.vue'),
    },
    {
      path: '/netint',
      name: 'Netint',
      component: () => import('@/views/Core/Netint.vue'),
    },
    {
      path: '/:name',
      props: true,
      component: () => import('@/views/Core/Channels/Channel.vue'),
    },
    {
      path: '/clip/:xid',
      props: true,
      component: () => import('@/views/Core/Global/Clip.vue'),
    },
    {
      path: '/videos/:xid',
      props: true,
      component: () => import('@/views/Core/Global/Video.vue'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
